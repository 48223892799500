@mixin spot-typography__heading--level-1 {
  position: absolute;
  width: 308px;
  height: 46px;
  left: 262px;
  top: 254px;

  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
  line-height: 46px;
  color: #000000;
}

@mixin spot-typography__text--tertiary() {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #6A6B6D;
  opacity: 0.8;
}

@mixin spot-typography__heading--level-5 {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

@mixin spot-typography__heading--level-4 {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
