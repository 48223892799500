@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/util.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '~@idexx/spot/dist/styles/shared/color.scss';

.page-header_wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 600;

  .page-header {
    background-color: $spot-color-neutral-97;
    padding: 8px 16px 0 16px;
    width: 100%;
    height: 45px;
  }

  .my-cornerstone {
    position: relative;
    color: $spot-color-neutral-42;
    font-size: 16px;
    top: 2px;
    margin-left: 2px;
    font-weight: 400;

    strong {
      font-weight: 700;
    }
  }

  .mycs-logo {
    display: inline-block;
    float: left;
    height: 24px;
    width: 24px;
    margin: 2px 4px 0 0;
  }

  .page-bar {
    background-color: $spot-color-teal-35;
    width: 100%;
    height: 46px;
  }
}
