.spot-paging__button {
  position: relative;

  a {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #97999b;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.pagination_truncated {
  border: none;
  padding: 3px 10px;
  border-right: 1px solid rgba(0,0,0,.05);

  +.pagination_truncated {
      display: none;
  }
}
