// Minty 4.3.1
// Bootswatch

//
// Color system
//

$white:      #fff !default;
$gray-100:   #f8f9fa !default;
$gray-200:   #f7f7f9 !default;
$gray-300:   #eceeef !default;
$gray-400:   #ced4da !default;
$gray-500:   #aaa !default;
$gray-600:   #888 !default;
$gray-700:   #5a5a5a !default;
$gray-800:   #343a40 !default;
$gray-900:   #212529 !default;
$gray:       #333;
$gray-border:#97999b !default;
$purple-border: #a05eb5 !default;
$black:     #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #f9423a !default;
$orange:  #fd7e14 !default;
$yellow:  #FFCE67 !default;
$green:   #56CC9D !default;
$teal:    #20c997 !default;
$cyan:    #6CC3D5 !default;

$primary:       #00a7b5 !default;
$secondary:     #006069 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-400 !default;
$dark:          $gray-800 !default;

$yiq-contrasted-threshold: 250 !default;

// Body

$body-color:                $gray !default;

// Components

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .15rem !default;

// Fonts

$headings-font-family:        "Open Sans", Helvetica, Arial, sans-serif !default;
$font-size-base:              0.9rem;
$font-family-sans-serif:      $headings-font-family;
$font-weight-normal:          300;
$font-weight-bold:            600;
$headings-font-weight:        300;
$headings-color:              $gray !default;

// Tables

$table-border-color:          rgba(0,0,0,0.05) !default;

// Dropdowns

$dropdown-link-hover-color:         $white !default;
$dropdown-link-hover-bg:            $secondary !default;

// Navbar

$navbar-dark-color:                 rgba($white,.6) !default;
$navbar-dark-hover-color:           $white !default;

$navbar-light-color:                rgba($black,.3) !default;
$navbar-light-hover-color:          $gray-700 !default;
$navbar-light-active-color:         $gray-700 !default;
$navbar-light-disabled-color:       rgba($black,.1) !default;

// Pagination

$pagination-color:                  $white !default;
$pagination-bg:                     $primary !default;
$pagination-border-color:           $primary !default;

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $secondary !default;
$pagination-hover-border-color:     $pagination-hover-bg !default;

$pagination-active-bg:              $secondary !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         $white !default;
$pagination-disabled-bg:            #CCE8E0 !default;
$pagination-disabled-border-color:  $pagination-disabled-bg !default;

// Breadcrumbs

$breadcrumb-bg:                     $primary !default;
$breadcrumb-divider-color:          $white !default;
$breadcrumb-active-color:           $breadcrumb-divider-color !default;


$table-active-bg:                   $gray-700;
