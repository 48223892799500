.spot-form.spot-search-bar {
  position: relative;

  .spot-search-bar__clear {
    position: absolute;
    cursor: pointer;
    font-size: 1.6em;
    right: 68px;
    top: 3px;
  }
}
