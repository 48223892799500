@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.roles-management-wrapper {
  .page-heading {
    width: 370px;
    position: relative;

    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      top: -5px;
    }
  }

  .roles-management-table {
    margin-left: 0;
    margin-top: 16px;
    font-size: 14px;
  }

  .roles-management_actions {
    margin: 40px 20px 0 20px;
    display: flex;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;
    }
  }
}
