@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.upgrade-management_wrapper {
  .page-heading {
    width: 370px;
    position: relative;

    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      top: -5px;
      white-space: nowrap;
    }

    .spot-typography__text--tertiary {
      @include spot-typography__text--tertiary;
      margin-bottom: 20px;
    }
  }

  .upgrade-management_head-actions {
    display: flex;
    justify-content: space-between;

    .align-left {
      justify-content: flex-start;
      display: inline-block;

      .search-bar-wrapper {
        display: inline-block;

        .spot-search-bar {
          width: 480px;
          margin-top: -8px;

          .spot-search-bar__search-button[disabled] {
            cursor: not-allowed;
            pointer-events: all !important;

            svg {
              cursor: inherit;
              fill: $spot-color-neutral-74;
            }
          }
        }
      }

      .show-future-upgrades-only {
        display: inline-block;
        position: relative;
        top: 8px;
        margin: 0 auto 0 20px;
        width: 240px;

        .spot-form__checkbox-label {
          font-size: 13px;
        }
      }

      .max-upgrades_wrapper {
        font-size: 13px;
        font-weight: 400;

        .text-box, .text-box input {
          width: 100px;
          height: 30px;
          margin-left: 10px;

          .spot-form__field-error {
            width: 100%;
            margin: 0 0 0 8px;
            padding: 0;
            font-size: 12px;
          }
        }

        .text-box_wrapper {
          margin-right: 16px;
        }

        .spot-button {
          padding: 0 4px 0 0;

          .spot-icon {
            fill: $spot-color-teal-35;
          }
        }
      }
    }

    .refresh-btn {
      margin-right: 20px;
      justify-content: flex-end;
      width: 102px;
      top: 6px;
    }
  }


  #upgrade-management-grid {
    margin-left: 0;
    margin-top: 16px;
    font-size: 13px;

    th {
      font-weight: 400;
      font-size: 13px;
    }

    .spot-data-table__col--index-8 {
      text-align: center;
    }

    td {
      margin-top: 5px;

      .spot-form__field-group--horizontal .spot-form__checkbox {
        margin-top: 1px;
      }

      .contact-name {
        white-space: nowrap;
      }

      .doc-link img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .upgrade-management_actions {
    margin: 40px 20px 0 20px;
    display: flex;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;
    }
  }
}
