@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.nav-items {
  display: block;
  z-index: 604;
  position: fixed;
  top: 0;
  right: 16px;

  @mixin level-4-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: $spot-color-teal-35;
  }

  .spot-di__header-username {
    @include level-4-heading;
    font-size: 15px;
  }

  .nav-item {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: 0 6px;
    line-height: 46px;
    vertical-align: middle;
    text-align: left;

    &.spot-crossproduct {
      display: inline-block;
      padding-right: 6px;
      width: 40px;
      z-index: 604;
    }
  }

  .app-menu-icon {
    cursor: pointer;
    height: 24px;
    width: 24px;
    top: 5px;
    box-sizing: border-box;
  }
}
