@import  './common/_variables.scss';
@import  './common/_bootswatch.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~@idexx/spot/dist/styles/spot_library.min.css';
@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/color.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';

body {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
}

.card {
  margin-bottom: 5px;

  span {
    margin-right: 5px;
  }
}
