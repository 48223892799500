@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.practice-user-management-wrapper {
  .heading--level-1 {
    @include spot-typography__heading--level-1;
    position: initial;
    white-space: nowrap;
  }

  .spot-typography__text--tertiary {
    @include spot-typography__text--tertiary;
    position: initial;
    margin-bottom: 20px;
  }

  .add-more-btn svg {
    border-radius: 6px;
    padding: 2px;
    fill: white;
    background-color: $spot-text-color-link-on-light;
  }

  #practice-users-management-grid {
    margin-left: 0;
    margin-top: 16px;
    font-size: 14px;

    th {
      font-weight: normal;
    }

    .plain-text {
      color: $spot-color-neutral-48;
      cursor: pointer;
      width: 100%;
    }

    .delete-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      fill: $spot-interactive-color-on-light;

      &.disabled {
        color: $spot-color-neutral-48;
        cursor: not-allowed;
      }
    }
  }

  .practice-user-management_actions {
    clear: both;
    display: flex;
    margin-left: 20px;
    margin-top: 40px;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;
    }
  }

}
