@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '../../styles/common/typography.scss';
@import '../../styles/common/variables';

.schedule-upgrade-wrapper {
  .heading--level-1 {
    @include spot-typography__heading--level-1;
    position: initial;
    white-space: nowrap;
  }

  .heading--level-2 {
    @include spot-typography__heading--level-1;
    position: initial;
    font-size: 24px;
    font-weight: 400;
  }

  .spot-typography__text--tertiary {
    @include spot-typography__text--tertiary;
    position: initial;
    margin-bottom: 20px;
  }

  .schedule-calendar {
    float: left;
    margin-right: 30px;
    margin-bottom: 50px;
  }

  .start-time-wrapper {
    display: inline-block;
    min-width: 410px;
    margin-top: 30px;
    margin-bottom: 30px;

    .start-time-label {
      font-size: 15px;
      font-weight: 400;
      color: $spot-color-neutral-12;
      margin-bottom: 12px;
    }

    .info-icon {
      width: 12px;
      height: 12px;
      margin-bottom: 2px;
      margin-left: 2px;
      fill: $spot-color-teal-35;
    }

    .time-select {
      display: inline-block;
      margin-right: 5px;
    }
  }

  p {
    margin-bottom: 0;

    &.show-date {
      font-weight: bold;
    }
  }

  .disclaimer {
    margin-top: 10px;
    max-width: 800px;

    p {
      margin-top: 10px;
    }
  }

  .bold {
    font-weight: bold;
  }

  .note-icon {
    width: 16px;
    height: 16px;
    fill: $red;
    margin-bottom: 3px;
    margin-right: 2px;
  }

  .schedule-upgrade_actions {
    clear: both;
    display: flex;
    margin-left: 20px;
    width: 580px;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;

      .spot-button {
        margin-right: 20px;
      }

      .schedule-btn {
        width: 100px;
      }
    }
  }

  #update-scheduled-modal {
    #update-scheduled-modal_inner {
      max-width: 715px;
    }
    .heading--level-2 {
      width: auto;
    }
  }
}
