@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.audit-wrapper {
  .page-heading {
    width: 370px;
    position: relative;

    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      top: -5px;
    }
  }

  .search-bar-wrapper {
    display: inline-block;

    .spot-search-bar {
      width: 450px;
      margin-top: 30px;

      .spot-search-bar__search-button[disabled] {
        cursor: not-allowed;
        pointer-events: all !important;

        svg {
          cursor: inherit;
          fill: $spot-color-neutral-74;
        }
      }
    }
  }

  .failures-only {
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 auto 0 20px;
    width: 198px;
  }

  .refresh-btn {
    float: right;
    margin-top: 42px;
  }

  .jump-to-audit-id-wrapper {
    display: inline-block;

    .jump-to-audit-id-text-box {
      width: 135px
    }
  }

  .jump-btn {
    margin-left: 5px;
    margin-top: 8px;
  }

  .type-dropdown-wrapper {
    margin-top: 10px;
  }

  .audit-table {
    margin-top: 30px;
    width: 100%;
    min-height: 250px;
    max-height: 400px;
    overflow-y: auto;

    .spot-data-table__col--index-0 {
      width: 35px;
    }
    .spot-data-table__col--index-1 {
      width: 80px;
    }
    .spot-data-table__col--index-4 {
      width: 150px;
    }
    .spot-data-table__col--index-5 {
      width: 90px;
    }
    .spot-data-table__col--index-6 {
      width: 95px;
    }

    tr {
      th, td {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 1px;
      }

      > :not(:last-child) {
        overflow: hidden;
      }
    }

    .expand-collapse-cell {
      text-align: left;
    }

    .expand-collapse-icon {
      width: 16px;
      height: 16px;
      fill: $spot-color-teal-35;
      cursor: pointer;
    }

    .copy-to-clipboard-icon {
      width: 16px;
      height: 16px;
      fill: $spot-color-teal-35;
      cursor: pointer;
      display: inline;
      margin-right: 5px;
    }
  }

  .audit-table-pagination {
    margin-top: 15px;
  }

  .audit_actions {
    margin: 40px 20px 0 20px;
    display: flex;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;
    }
  }
}
