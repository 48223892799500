@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.footer {
  position: fixed;
  bottom: 0;
  padding: 36px 0;
  width: 100%;
  height: 80px;
  z-index: 600;

  background-color: $spot-color-neutral-42;
  border: 1px solid #D1D2D2;

  .footer-logo {
    float: left;
    margin-left: 30px;
    min-width: 160px;
    width: 10%;
  }

  img {
    height: 21px;
    left: 31px;
    max-width: 100%;
  }

  .copyright {
    margin: 0 30px;
    text-align: right;
    float: none;
    color: $spot-color-neutral-74;
  }
}


