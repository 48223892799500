@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.calendar-with-offset {
  position: relative;

  .react-datepicker {
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 15px;
    border: 0;

    &__month-container {
      .react-datepicker__header {
        background-color: inherit;
        border-bottom: 0;
        font-weight: 400;

        .react-datepicker__day-name {
          width: 2.5rem;
          font-size: 18px;
        }
      }
    }

    &__current-month {
      color: $spot-color-teal-35;
      font-size: 26px;
      font-weight: normal;
      margin-bottom: 10px;
    }

    &__navigation {
      top: 7px;

      &--previous {
        left: 18px;
      }

      &--next {
        right: 18px;
      }

      &-icon {
        top: 6px;

        &::before {
          border-color: $spot-color-teal-35;
          width: 15px;
          height: 15px;
        }
      }
    }

    &__day {
      color: $spot-color-teal-35;
      width: 2.5rem;
      height: 2.5rem;
      padding-top: 6px;
      background: inherit;

      &--selected {
        font-weight: 800;
        background-color: $spot-color-teal-93;
        border-radius: 2.5rem;
      }

      &--today {
        font-weight: normal;
      }

      &--disabled {
        color: $spot-color-neutral-74;
      }
    }

    &-popper {
      padding: 0;
      border: 1px solid black;

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__header--time {
        display: none;
      }

      .react-datepicker__time-container {
        width: 198px;

        .react-datepicker__time-box {
          width: 100%;

          .react-datepicker__time-list {
            .react-datepicker__time-list-item {
              text-transform: lowercase;
              text-align: left;
              padding: 0 10px;
              height: auto;
            }
          }
        }
      }
    }
  }

  .react-datepicker-wrapper {
    width: auto;

    &::after {
      content: '';
      display: block;
      position: absolute;
      border-color: $spot-color-neutral-74;
      border-style: solid;
      border-width: 0 4px 4px 0;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      top: 8px;
      right: 15px;
    }

    .react-datepicker__input-container {
      input {
        width: 200px;
        font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
        font-size: 15px;
        border: 1px solid $spot-color-neutral-74;
        border-radius: 2px;
        cursor: pointer;
        padding: 6px 10px 6px 10px;
        text-transform: lowercase;

        &:focus {
          outline: none !important;
          border: 1px solid $spot-color-teal-35;
        }
      }
    }
  }
}
