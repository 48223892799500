@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.content-header {
  display: flex;
  align-items: center;
  background-color: $spot-color-neutral-97;
  padding: 0 10px;
  min-height: 45px;
  font-size: 26px;
  vertical-align: middle;
  color: black;
}
