@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.spot-card--primary {
  .spot-card--header {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    background-color: $spot-color-neutral-97;

    &:after {
      border-bottom: 0;
    }
  }

  .spot-card--body {
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
