@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.service-events-wrapper {
  .page-heading {
    width: 370px;
    position: relative;

    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      top: -5px;
      white-space: nowrap;
    }
  }

  #service-events-grid {
    margin-left: 0;
    margin-top: 16px;
    font-size: 14px;

    th {
      font-weight: normal;
    }

    td {
      vertical-align: top;
    }

    .plain-text {
      color: $spot-color-neutral-48;
      cursor: pointer;
      width: 100%;
    }
  }

  .service-events_actions {
    clear: both;
    margin: 40px 20px 0 20px;
    display: flex;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;
    }
  }

  .refresh-btn {
    margin-top: 1px;
  }

  .spot-typography__text--tertiary {
    @include spot-typography__text--tertiary;
    position: initial;
  }

  .text-box_wrapper {
    margin-bottom: 5px;
  }

  .date-wrapper {
    display: flex;
    justify-content: center;
  }

  .time-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
}
