@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.spot-icon-button {
  padding: 0 5px;

  .spot-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: $spot-interactive-color-on-light;

    &.disabled {
      fill: $spot-color-neutral-74;
      cursor: not-allowed;
    }
  }
}
