@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.practice-selector_container {
  display: inline-block;
  vertical-align: middle;
  margin: -6px 6px 0 6px;

  .select-search-container {
    .select-search-value {
      width: 100%;
      font-size: 12px;
      line-height: 1.3em;
      font-weight: bold;
      padding: 0 10px;
      box-sizing: border-box;
      background: $spot-color-white none;
      border: 1px solid $spot-color-neutral-81;
      border-radius: 0.25rem;

      .select-search-input {
        height: 38px;
        width: 100%;
        border: none;
        padding: 2px 0 0 0;
        font-size: 14px;
        cursor: pointer;
      }

      div.select-search-input {
        cursor: pointer;
        color: $spot-color-neutral-28;
        background: $spot-color-white none;
        overflow-x: hidden;
        font: inherit;
        padding-top: 4px;

        span {
          display: block;

          &:nth-child(2) {
            font-weight: normal;
            color: $spot-color-teal-35;
            white-space: nowrap;
          }
        }

        .down-arrow {
          transform: rotate(45deg);
          border-right: 2px solid $spot-color-neutral-6;
          border-bottom: 2px solid $spot-color-neutral-6;
          pointer-events: none;
          content: '';
          display: inline-block;
          position: absolute;
          top: 12px;
          right: 6px;
          width: 11px;
          height: 11px;
          z-index: 999;
        }
      }
    }

    .select-search-select {
      top: 38px;
      border: 1px solid $spot-color-neutral-81;
      box-shadow: 0 7px 14px 0 rgb(50 50 93 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
      border-radius: 4px;

      .select-search-options {
        padding: 0;
        margin-bottom: 0;
        max-height: 335px;

        .select-search-option {
          padding: 0 10px;
          color: $spot-color-neutral-28;
          font-size: 12px;
          line-height: 1.3em;

          > div {
            font-weight: bold;
          }

          > span {
            color: $spot-color-teal-35;
            display: block;
          }

          &:not([disabled]).select-search-is-selected {
            background: $spot-color-teal-35;
            > div, > span {
              color: $spot-color-white;
            }
          }
        }
      }
    }

    &.select-search-is-disabled {
      .select-search-value {
        background-color: $spot-color-neutral-97;

        .select-search-input {
          background-color: $spot-color-neutral-97;
        }
      }
    }
  }
}
