@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '../../styles/common/typography.scss';
@import '../../styles/common/variables';

.acknowledgment-wrapper {
  .heading--level-1 {
    @include spot-typography__heading--level-1;
    position: initial;
    white-space: nowrap;
  }

  .heading--level-2 {
    @include spot-typography__heading--level-1;
    position: initial;
    font-size: 24px;
    font-weight: 400;
  }

  .spot-typography__text--tertiary {
    @include spot-typography__text--tertiary;
    position: initial;
    margin-bottom: 32px;
  }

  .ack-header {
    font-weight: bold;
  }

  .ack-step {
    display: block;
    margin-bottom: 8px;
    max-width: 1100px;

    ul {
      margin-bottom: 0;
    }

    a.disabled-link {
      pointer-events: none;
    }
  }

  .acknowledgment_actions {
    clear: both;
    display: flex;
    margin-top: 40px;
    margin-left: 20px;
    max-width: 1100px;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;

      .spot-button {
        margin-right: 20px;
      }
    }
  }
}
