.text-box_wrapper {
  &.in-cell {
    .spot-form__input {
      height: 27px;
      padding: 0 4px;
      font-size: 14px;
    }

    .spot-form__field-error {
      margin: 0;
      padding: 0 0 0 2px;

      .spot-form__field-error-text {
        font-size: .8em;
      }
    }
  }
}
