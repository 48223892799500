@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.confirmation-wrapper {
  .page-heading {
    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      width: auto;
      top: -5px;
      white-space: nowrap;
    }

    .spot-typography__text--tertiary {
      @include spot-typography__text--tertiary;
    }
  }

  .steps-list {
    max-width: 710px;

    dt {
      margin-top: 24px;
      color: $spot-color-neutral-36;
      font-weight: normal;

      &.doc-header {
        margin-bottom: 16px;
      }

      .qualification-icon {
        margin-left: 8px;
      }
    }

    dd {
      margin-left: 50px;
      font-weight: bolder;
      color: black;
      margin-bottom: 0;

      &.doc-error-message {
        font-weight: 800;
        color: $spot-color-sales-red-38;

        &.last {
          margin-bottom: 20px;
        }
      }

      .doc-icon {
        width: 48px;
        height: 48px;
        padding-right: 4px;
      }
    }

    .add-more-btn svg {
      border-radius: 6px;
      padding: 2px;
      fill: white;
      background-color: $spot-text-color-link-on-light;
    }

    .gray-note {
      color: $spot-color-neutral-60;
      font-size: 12px;
    }

    #notifications-grid {
      margin-left: 0;
      margin-top: 16px;
      font-size: 14px;

      th {
        font-weight: normal;
      }

      .plain-text {
        color: $spot-color-neutral-48;
        width: 100%;
      }
    }
  }

  .confirmation_actions {
    display: flex;
    max-width: 710px;
    margin-top: 86px;
    justify-content: space-between;

    .spot-button {
      margin-left: 20px;
    }
  }
}
