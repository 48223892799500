@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.spot-icon.qualification-icon {
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
  fill: $spot-color-red-60;
  cursor: auto;

  &.pass {
    fill: $spot-color-green-45;
  }
}
