@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.dashboard-wrapper {

  .page-heading {
    max-width: 880px;
    position: relative;
    color: black;

    .heading--level-1 {
      margin: 2px 0 4px 0;
      font-size: 42px;
      font-weight: 300;
      line-height: 46px;
    }

    .spot-typography__text--tertiary {
      max-width: 880px;
      margin-bottom: 42px;
      font-size: 15px;
      color: black;
    }
  }

  .business {
    max-width: 1060px;
    margin-bottom: 32px;

    .expand-toggle {
      cursor: pointer;
      font-weight: 400;

      .caret-icon {
        fill: $spot-color-teal-35;
        width: 16px;
        margin-right: 8px;
      }
    }

    .business-cards {
      padding: 32px 0 0 32px;

      &.collapsed {
        display: none;
      }

      .business-card {
        display: inline-block;
        border: 1px solid $spot-color-teal-35;
        border-radius: 4px;
        margin-right: 34px;
        width: 170px;
        height: 240px;

        .card-icon {
          height: 100px;
          margin-bottom: 28px;
        }

        .card-body-title {
          font-size: 13.5px;
          height: 40px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
