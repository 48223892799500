@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.spot-data-table.spot-data-table--small-spacing {
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;

  thead {
    position: sticky;
    top: 0;
    z-index: 100;
    border-bottom: 1px solid $spot-text-color-primary-on-dark;
  }

  tr {
    &.hover-gray:hover {
      background-color: $spot-color-neutral-97;

      & + .subrow {
        background-color: $spot-color-neutral-97;
      }
    }

    &.gray-row {
      background-color: $spot-color-neutral-97;
    }

    td {
      white-space: pre-wrap;

      &.no-data-row {
        text-align: center;
        padding: 20px 0;
      }
    }
  }

  th {
    &.spot-data-table__col--sort-disabled {
      cursor: auto;
    }

    &.spot-data-table__col--inactive {
      position: relative;

      &:after {
        content: '\01F890\2009\01F892';
        font-size: 24px;
        position: absolute;
        right: 0;
        top: 2px;
        transform: rotate(90deg);
      }
    }

    .spot-form__checkbox {
      margin: 0 0 -5px 0;
    }

    .spot-form__checkbox-label {
      font-weight: bold;
      color: $spot-color-neutral-12;
    }
  }
}
