// Bootswatch
// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" !default;
@import url($web-font-path);

// Navbar ======================================================================

body {
    font-weight: 300;
}

.navbar {
    font-family: $headings-font-family;
}

.bg-dark {
    background-color: $secondary !important;
}

.border-dark {
    border-color: $secondary !important;
}

// Buttons =====================================================================

.btn {
    font-family: $headings-font-family;

    .spinner-border {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    }

    &.loading {
      position: relative;

      span {
        visibility: hidden;
      }

      .spinner-border {
        display: block;
      }
    }

    &-primary {
      box-shadow: 0 0 6px 0 #97999b;
    }

    &,
    &:hover {
        color: $white;
    }

    &-light,
    &-light:hover {
        color: $gray-700;
    }

    &-link,
    &-link:hover {
        color: $primary;
    }

    &-link.disabled:hover {
        color: $gray-600;
    }

    &-outline-primary {
        color: $primary;
    }

    &-outline-secondary {
        color: $secondary;
    }

    &-outline-success {
        color: $success;
    }

    &-outline-info {
        color: $info;
    }

    &-outline-warning {
        color: $warning;
    }

    &-outline-danger {
        color: $danger;
    }

    &-outline-dark {
        color: $dark;
    }

    &-outline-light {
        color: $dark;
    }

    &.btn-lg {
        font-weight: 500;
    }

    &-white {
      background-color: $white;
      color: $primary;
      font-weight: normal;

      &:hover {
        color: $primary;
      }
    }
}

.btn-group {
  .btn {
    &-primary {
      box-shadow: none;
    }
  }
}

// Typography ==================================================================

// Tables ======================================================================

.table {
    &-primary,
    &-secondary,
    &-success,
    &-info,
    &-warning,
    &-danger {
        color: #fff;
    }

    &-primary {
        &,
        > th,
        > td {
            background-color: $primary;
        }
    }

    &-secondary {
        &,
        > th,
        > td {
            background-color: $secondary;
        }
    }

    &-light {
        &,
        > th,
        > td {
            background-color: $light;
        }
    }

    &-dark {
        &,
        > th,
        > td {
            background-color: $dark;
        }
    }

    &-success {
        &,
        > th,
        > td {
            background-color: $success;
        }
    }

    &-info {
        &,
        > th,
        > td {
            background-color: $info;
        }
    }

    &-danger {
        &,
        > th,
        > td {
            background-color: $danger;
        }
    }

    &-warning {
        &,
        > th,
        > td {
            background-color: $warning;
        }
    }

    &-active {
        &,
        > th,
        > td {
            background-color: $table-active-bg;
        }
    }

    &-hover {
        .table-primary:hover {
            &,
            > th,
            > td {
                background-color: darken($primary, 5%);
            }
        }

        .table-secondary:hover {
            &,
            > th,
            > td {
                background-color: darken($secondary, 5%);
            }
        }

        .table-light:hover {
            &,
            > th,
            > td {
                background-color: darken($light, 5%);
            }
        }

        .table-dark:hover {
            &,
            > th,
            > td {
                background-color: darken($dark, 5%);
            }
        }

        .table-success:hover {
            &,
            > th,
            > td {
                background-color: darken($success, 5%);
            }
        }

        .table-info:hover {
            &,
            > th,
            > td {
                background-color: darken($info, 5%);
            }
        }

        .table-danger:hover {
            &,
            > th,
            > td {
                background-color: darken($danger, 5%);
            }
        }

        .table-warning:hover {
            &,
            > th,
            > td {
                background-color: darken($warning, 5%);
            }
        }

        .table-active:hover {
            &,
            > th,
            > td {
                background-color: $table-active-bg;
            }
        }
    }

    .thead-dark th {
        background-color: $primary;
        border-color: $table-border-color;
        font-family: $headings-font-family;
    }
}

// Forms =======================================================================

legend {
    font-family: $headings-font-family;
}

.form-control {
    border-color: $gray-border;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 1.5;
    color: $gray-700;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $gray-400;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        border: 1px solid transparent;
        box-shadow: 0 0 0 1px #00a7b5;
    }

    &-sm {
      height: calc(1.5em + 0.5rem + 2px) !important;
      padding: 0.25rem 0.5rem !important;
      font-size: 0.7875rem !important;
      line-height: 1.5 !important;
      border-radius: 0.15rem !important;
  }
}

// Navs ========================================================================

.dropdown-menu {
    font-family: $font-family-sans-serif;
}

.breadcrumb {
    a {
        color: $navbar-dark-color;
    }

    a:hover {
        color: $white;
        text-decoration: none;
    }
}

// Indicators ==================================================================

.alert {
    color: $white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit;
        font-weight: 300;
    }

    a,
    .alert-link {
        color: $white;
    }

    &-primary {
        &,
        > th,
        > td {
            background-color: $primary;
        }
    }

    &-secondary {
        &,
        > th,
        > td {
            background-color: $secondary;
        }
    }

    &-success {
        &,
        > th,
        > td {
            background-color: $success;
        }
    }

    &-info {
        &,
        > th,
        > td {
            background-color: $info;
        }
    }

    &-danger {
      border: 0;
        &,
        > th,
        > td {
            background-color: $danger;
        }
    }

    &-warning {
      color: #323232;
        &,
        > th,
        > td {
            background-color: $warning;
        }
    }

    &-dark {
        &,
        > th,
        > td {
            background-color: $dark;
        }
    }

    &-light {
        &,
        > th,
        > td {
            background-color: $light;
        }
    }

    &-light {
        &,
        & a:not(.btn),
        & .alert-link {
            color: $body-color;
        }
    }
}

.badge {
    color: $white;

    &-light {
        color: $gray-700;
    }

    &-outline {
      color: $gray;
      border: 1px solid $gray-border;
      font-weight: normal;
    }
}

// Progress bars ===============================================================

// Containers ==================================================================

.card,
.list-group-item {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit;
    }
}

.shadow {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
}

.dropdown-menu {
    border: 1px solid #e3e6f0;
}