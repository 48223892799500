@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.users-management-wrapper {
  .page-heading {
    width: 370px;
    position: relative;

    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      top: -5px;
    }
  }

  #users-management-grid {
    margin-left: 0;
    margin-top: 16px;
    font-size: 14px;

    th {
      font-weight: normal;
    }

    td {
      vertical-align: top;
      margin-top: 5px;

      .spot-form__field-group--horizontal .spot-form__checkbox {
        margin-top: 1px;
      }
    }

    .plain-text {
      color: $spot-color-neutral-48;
      cursor: pointer;
      width: 100%;
    }
  }

  .user-management_actions {
    clear: both;
    margin: 40px 20px 0 20px;
    display: flex;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;
    }
  }

  .user-management_filter {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 90%;

    .search-bar-wrapper {
      display: inline-block;
      margin-left: 20px;

      .spot-search-bar {
        width: 380px;
        margin-top: -8px;

        .spot-search-bar__search-button[disabled] {
          cursor: not-allowed;
          pointer-events: all !important;

          svg {
            cursor: inherit;
            fill: $spot-color-neutral-74;
          }
        }
      }
    }

    .hide-guests {
      display: inline-block;
      position: relative;
      top: 5px;
      margin: 0 auto 0 20px;
      width: 110px;
    }
  }
}

.add-users {
  & > .spot-button {
    margin-right: 5px;
  }

  input[type="text"] {
    max-width: 100%;
  }

  .spot-toggle-label {
    position: relative;
    top: 7px;
  }

  input[type="file"] {
    display: none;
  }

  .error-message {
    color: red;
    font-size: 13px;
  }

  .add-users_actions {
    text-align: right;
    margin-top: 10px;

    .spot-button {
      margin-left: 10px;
    }
  }
}
