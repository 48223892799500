$spot-color-white: #ffffff;
$spot-color-neutral-6: #0f0f10;
$spot-color-neutral-12: #1e1f1f;
$spot-color-neutral-18: #2d2e2f;
$spot-color-neutral-24: #3c3d3e;
$spot-color-neutral-28: #464748;
$spot-color-neutral-30: #4c4d4e;
$spot-color-neutral-36: #5b5c5d;
$spot-color-neutral-42: #6a6b6d;
$spot-color-neutral-48: #797a7c;
$spot-color-neutral-60: #97999b;
$spot-color-neutral-74: #babdbf;
$spot-color-neutral-81: #cccfd1;
$spot-color-neutral-91: #e6e8ea;
$spot-color-neutral-97: #f4f6f7;
$spot-color-teal-26: #29575b;
$spot-color-teal-27: #3c4c4e;
$spot-color-teal-31: #3b5e61;
$spot-color-teal-35: #00a7b5;
$spot-color-teal-50: #33bfcc;
$spot-color-teal-70: #88d8df;
$spot-color-teal-80: #aee5ea;
$spot-color-teal-87: #ccedf0;
$spot-color-teal-93: #e5f6f7;
$spot-color-sales-red-38: #a61e22;
$spot-color-sales-red-50: #d8292f;
$spot-color-sales-red-96: #fcf0f0;
$spot-color-red-52: #f42e35;
$spot-color-red-60: #f9423a;
$spot-color-red-65: #fa5b52;
$spot-color-red-73: #ff9796;
$spot-color-yellow-35: #947c37;
$spot-color-yellow-50: #cfa841;
$spot-color-yellow-59: #e6bb48;
$spot-color-yellow-66: #ebc96d;
$spot-color-yellow-74: #F3DA98;
$spot-color-green-25: #377c2a;
$spot-color-green-32: #42991c;
$spot-color-green-45: #59b730;
$spot-color-green-58: #7bd355;
$spot-color-green-65: #9Bda8c;

$spot-background-color-light-primary: #ffffff;
$spot-background-color-light-secondary: #f4f6f7;
$spot-background-color-light-disabled: #e6e8ea;
$spot-background-color-dark-primary: #3c3d3e;
$spot-background-color-dark-secondary: #4c4d4e;
$spot-background-color-controls-on-light: #ffffff;
$spot-background-color-controls-on-dark: #3c3d3e;

$spot-text-color-primary-on-light: #1e1f1f;
$spot-text-color-primary-on-dark: #babdbf;
$spot-text-color-secondary-on-light: #6a6b6d;
$spot-text-color-secondary-on-dark: #97999b;
$spot-text-color-active-on-light: #0f0f10;
$spot-text-color-active-on-dark: #ffffff;
$spot-text-color-disabled-on-light: #cccfd1;
$spot-text-color-disabled-on-dark: #6a6b6d;
$spot-text-color-link-on-light: #00a7b5;
$spot-text-color-link-on-dark: #88d8df;

$spot-feedback-color-error-on-light: #f9423a;
$spot-feedback-color-error-on-dark: #fa5b52;
$spot-feedback-color-error-background-on-light: rgba(249,66,58, .06);
$spot-feedback-color-error-background-on-dark: rgba(250,91,82, .06);
$spot-feedback-color-success-on-light: #59b730;
$spot-feedback-color-success-on-dark: #9Bda8c;
$spot-feedback-color-warning-on-light: #e6bb48;
$spot-feedback-color-warning-on-dark: #ebc96d;
$spot-feedback-color-results-in-range: rgba(89,183,48, .08);
$spot-feedback-color-results-out-of-range: rgba(249,66,58, .08);

$spot-interactive-color-on-light: #00a7b5;
$spot-interactive-color-on-dark: #88d8df;
$spot-interactive-color-hover-on-light-primary: #e5f6f7;
$spot-interactive-color-hover-on-light-secondary: #ccedf0;
$spot-interactive-color-hover-on-dark-primary: #3b5e61;
$spot-interactive-color-hover-on-dark-secondary: #3c4c4e;

$spot-border-radius-s: 2px;
$spot-border-radius-m: 4px;
$spot-border-radius-l: 13px;
$spot-border-heavy-on-light-primary: 1px solid #babdbf;
$spot-border-heavy-on-light-secondary: 1px solid #97999b;
$spot-border-heavy-on-dark-primary: 1px solid #97999b;
$spot-border-heavy-on-dark-secondary: 1px solid #babdbf;
$spot-border-heavy-color-on-light-primary: #babdbf;
$spot-border-heavy-color-on-light-secondary: #97999b;
$spot-border-heavy-color-on-dark-primary: #97999b;
$spot-border-heavy-color-on-dark-secondary: #babdbf;
$spot-border-light-on-light-primary: 1px solid #e6e8ea;
$spot-border-light-on-light-secondary: 1px solid #cccfd1;
$spot-border-light-on-dark-primary: 1px solid #5b5c5d;
$spot-border-light-on-dark-secondary: 1px solid #6a6b6d;
$spot-border-light-color-on-light-primary: #e6e8ea;
$spot-border-light-color-on-light-secondary: #cccfd1;
$spot-border-light-color-on-dark-primary: #5b5c5d;
$spot-border-light-color-on-dark-secondary: #6a6b6d;
$spot-border-control-on-light: 1px solid #babdbf;
$spot-border-control-on-dark: 1px solid #797a7c;
$spot-border-control-color-on-light: #babdbf;
$spot-border-control-color-on-dark: #797a7c;
$spot-border-control-hover: 1px solid #97999b;

$spot-font-family-sans-serif: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
$spot-font-family-code: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$spot-font-size-xs: 12px;
$spot-font-size-s: 13.5px;
$spot-font-size-m: 15px;
$spot-font-size-l: 18px;
$spot-font-size-header-level-1: 42px;
$spot-font-size-header-level-2: 34px;
$spot-font-size-header-level-3: 26px;
$spot-font-size-header-level-4: 18px;
$spot-font-size-header-level-5: 18px;
$spot-font-size-form-input: 15px;
$spot-font-size-form-label: 15px;
$spot-font-size-metadata: 12px;
$spot-font-size-code: 13.5px;
$spot-font-weight-light: 300;
$spot-font-weight-normal: 400;
$spot-font-weight-bold: 700;
$spot-font-line-height-default: 1.5;

$spot-size-icon-xs: 12px;
$spot-size-icon-s: 16px;
$spot-size-icon-m: 20px;
$spot-size-icon-l: 24px;

$spot-space-xs: 4px;
$spot-space-s: 8px;
$spot-space-m: 16px;
$spot-space-l: 32px;
$spot-space-xl: 64px;
$spot-space-inset-half: 8px;
$spot-space-inset-three-quarter: 12px;
$spot-space-inset-one: 16px;
$spot-space-inset-one-and-a-half: 24px;
$spot-space-inset-two: 32px;
$spot-space-inset-four: 64px;
$spot-space-inset-squish-quarter: 2px 4px;
$spot-space-inset-squish-half: 4px 8px;
$spot-space-inset-squish-one: 8px 16px;
$spot-space-inset-squish-one-and-a-half: 12px 24px;
$spot-space-inset-squish-two: 16px 32px;
$spot-space-inset-squish-four: 32px 64px;
$spot-space-stack-eighth: 0 0 2px 0;
$spot-space-stack-quarter: 0 0 4px 0;
$spot-space-stack-half: 0 0 8px 0;
$spot-space-stack-three-quarter: 0 0 12px 0;
$spot-space-stack-one: 0 0 16px 0;
$spot-space-stack-one-and-a-half: 0 0 24px 0;
$spot-space-stack-two: 0 0 32px 0;
$spot-space-stack-four: 0 0 64px 0;
$spot-space-stack-six: 0 0 96px 0;
$spot-space-stack-eight: 0 0 128px 0;
$spot-space-inline-left-quarter: 0 4px 0 0;
$spot-space-inline-left-half: 0 8px 0 0;
$spot-space-inline-left-three-quarter: 0 12px 0 0;
$spot-space-inline-left-one: 0 16px 0 0;
$spot-space-inline-left-one-and-a-half: 0 24px 0 0;
$spot-space-inline-left-two: 0 32px 0 0;
$spot-space-inline-left-four: 0 64px 0 0;
$spot-space-inline-left-six: 0 96px 0 0;
$spot-space-inline-right-quarter: 0 0 0 4px;
$spot-space-inline-right-half: 0 0 0 8px;
$spot-space-inline-right-three-quarter: 0 0 0 12px;
$spot-space-inline-right-one: 0 0 0 16px;
$spot-space-inline-right-one-and-a-half: 0 0 0 24px;
$spot-space-inline-right-two: 0 0 0 32px;
$spot-space-inline-right-four: 0 0 0 64px;
$spot-space-inline-right-six: 0 0 0 96px;

$spot-shadow-control-default: 0 0 6px 0 #97999b;
$spot-shadow-control-hovered: 0 0 8px 0 #97999b;
$spot-shadow-control-on-dark-default: 0 0 6px 0 #2d2e2f;
$spot-shadow-control-on-dark-hovered: 0 0 8px 0 #2d2e2f;
$spot-shadow-color: rgba(0, 0, 0, .05);

$spot-z-index-global-navigation: 0;
$spot-z-index-default: 10;
$spot-z-index-content: 10;
$spot-z-index-page-header: 100;
$spot-z-index-page-header-sticky: 110;
$spot-z-index-dialog: 200;
$spot-z-index-popup: 600;
$spot-z-index-modal: 800;
$spot-z-index-toast: 10000;

$spot-theme-namespace: spot;
