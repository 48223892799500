@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.check-qualifications-wrapper {
  .page-heading {
    width: 370px;
    margin-bottom: 75px;
    position: relative;

    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      top: -5px;
      white-space: nowrap;
    }

    .spot-typography__text--tertiary {
      @include spot-typography__text--tertiary;
      width: 370px;
    }
  }

  .find-computers-btn {
    margin-top: $spot-space-inset-four;
  }

  a {
    font-weight: bold;
  }

  .instructions {
    margin-top: $spot-space-inset-four;

    p {
      margin-bottom: .5rem;
    }

    .bold {
      font-weight: bold;
    }

    a {
      padding-left: .3rem;
      padding-right: .3rem;

      &.send-instructions.disabled {
        pointer-events: none;
        cursor: not-allowed;
        color: $spot-color-neutral-18;
      }
    }

    ol {
      margin-top: 1rem;
      margin-bottom: 2rem;

      li {
        margin-bottom: .5rem;

        &.no-index {
          display: block;
          margin-bottom: .8rem;
        }
      }
    }
  }

  .card-upgrade-status-wrapper {
    position: absolute;
    width: 210px;
    left: 820px;
    top: 10px;

    .spot-typography__text--secondary {
      padding-top: 5px;
    }

    .spot-typography__text--body {
      padding-bottom: 10px;

      .display-status-value-wrapper {
        display: inline-block;

        &:first-child {
          margin-right: 5px;
        }

        .total-count {
          @include spot-typography__heading--level-4;
          color: $spot-color-neutral-48;
        }
      }
    }
  }

  .card-upgrade-questions-wrapper {
    position: absolute;
    width: 265px;
    left: calc(90% - 257px);
    top: 10px;
    z-index: -1;

    .spot-card--primary {
      padding: 0 10px 5px 10px;
    }

    .spot-typography__text--secondary {
      padding: 5px 0;
    }

    .contact-phone {
      padding-bottom: 5px;

      a {
        font-weight: normal;

        .spot-icon {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .check-qualifications-note {
    margin-top: 16px;
    height: 40px;
    width: 90%;
    font-size: $spot-font-size-l;
    background: $spot-color-neutral-97;
    display: flex;
    align-items: center;
    box-shadow: 0 0 6px 0 $spot-color-neutral-60;

    .note-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .note-icon-wrapper {
      height: 40px;
      width: 40px;
      background: $spot-color-white;
      display: flex;
      align-items: center;
      margin-right: 20px;

      .note-icon {
        margin-left: auto;
        margin-right: auto;
        fill: $spot-color-yellow-59;
      }
    }
  }

  .check-qualifications_actions {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 90%;

    .search-bar-wrapper {
      display: inline-block;

      .spot-search-bar {
        width: 360px;
        margin-top: -8px;

        .spot-search-bar__search-button[disabled] {
          cursor: not-allowed;
          pointer-events: all !important;

          svg {
            cursor: inherit;
            fill: $spot-color-neutral-74;
          }
        }
      }
    }

    .hide-do-not-upgrade {
      display: inline-block;
      position: relative;
      top: 5px;
      margin: 0 auto 0 20px;
      width: 198px;
    }

    .get-help-link {
      display: inline-block;
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  .check-qualifications_bottom-actions {
    display: flex;
    margin-top: 30px;
    width: 90%;
    justify-content: space-between;

    .spot-button {
      margin-left: 20px;
    }

    .initiate-check-btn {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .spot-search-icon {
    width: 16px;
    height: 16px;

    flex: none;
    order: 0;
    flex-grow: 0;

    position: absolute;
    left: 0;
    right: 0.81%;
    top: 0;
    bottom: 0.81%;
  }

  .check-qualifications-table {
    margin-top: 30px;
    width: 90%;
    min-height: 250px;
    max-height: 440px;
    overflow-y: auto;

    .spot-data-table__col--index-0 {
      width: 40px;
    }
    .spot-data-table__col--index-1 {
      width: 65px;
    }
    .spot-data-table__col--index-7 {
      width: 70px;
    }

    tr:not(.subrow) {
      th, td {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 1px;
        .spot-form__checkbox-input:focus + .spot-form__checkbox-inner {
          box-shadow: none;
        }
      }

      > :not(:last-child) {
        overflow: hidden;
      }
    }

    .label-cell {
      cursor: pointer;
      display: block;
      width: 100%;

      &.gray-span {
        color: $spot-color-neutral-74;
      }
    }

    .actions-cell {
      text-align: center;
    }

    .spot-form__checkbox {
      margin: 0;
    }

    .spot-popover .spot-button {
      display: block;
      height: 20px;
      margin: 0;
      padding: 0;
    }

    .expand-collapse-icon {
      width: 16px;
      height: 16px;
    }
  }

  .spot-icon {
    fill: $spot-color-teal-35;
    cursor: pointer;
  }

  .subrow-qualifications {
    padding: $spot-space-inline-right-two;

    .subrow-title {
      float: left;
      width: 20%;
      padding-top: 40px;

      .subrow-header {
        @include spot-typography__heading--level-5;
        color: $spot-color-neutral-60;
      }
    }

    .subrow-content {
      float: left;
      width: 80%;

      .section-title {
        margin-bottom: 4px;
      }

      .flex-box {
        display: inline-flex;

        ul {
          margin-right: 32px;
        }

        ul, li {
          font-size: 0.9em;
          list-style: none;
          padding-left: 0;
        }
      }
    }

    .subrow-header-text {
      @include spot-typography__text--tertiary;
      color: $spot-color-teal-35;
    }

    .info-icon {
      width: 12px;
      height: 12px;
      margin-bottom: 2px;
    }
  }
}

.failed-qualifications-modal-content {
  font-size: 14px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
