@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.upgrade-version-management_wrapper {
  .page-heading {
    position: relative;

    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      top: -5px;
      white-space: nowrap;
    }

    .spot-typography__text--tertiary {
      @include spot-typography__text--tertiary;
      margin-bottom: 20px;
    }
  }

  .upgrade-version_top-bar {
    margin-bottom: 5px;
  }

  .version-header {
    width: calc(100% - 20px);
    margin-bottom: 2px;

    .spinner-loader {
      position: relative;
      top: -2px;
    }

    .spot-button {
      padding: 0 6px;
    }

    .expand-toggle {
      cursor: pointer;
      font-weight: 400;
      display: flex;
      justify-content: space-between;

      .left-align {
        justify-content: flex-start;
      }

      .right-align {
        justify-content: flex-end;
      }

      .caret-icon {
        fill: $spot-color-teal-35;
        width: 16px;
        margin-right: 8px;
      }

      .actions-popover {
        text-align: center;

        .spot-icon {
          fill: $spot-color-teal-35;
        }

        .spot-popover .spot-button {
          display: block;
          height: 20px;
          margin: 0;
          padding: 0;
        }
      }
    }

    .version-content {
      padding: 8px 0 0 32px;
      display: inline-flex;
      font-family: Open Sans, Helvetica Neue, Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;

      &.collapsed {
        display: none;
      }

      ul {
        margin-right: 32px;
      }

      ul, li {
        list-style: none;
        padding-left: 0;
      }

      li {
        padding: 8px 0 8px 0;
      }

      strong {
        font-weight: 800;
      }
    }
  }

  .upgrade-version_actions {
    clear: both;
    margin: 40px 20px 0 20px;
    display: flex;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;
    }
  }
}
