@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.spinner-loader {
  display: inline-table;

  span {
    margin-left: 12px;
    font-weight: 400;
    font-size: 15px;
    color: $spot-color-neutral-24;
  }

  .spinner {
    animation: spinner 1.3s linear infinite;
    fill: $spot-interactive-color-on-light;
  }

  @keyframes spinner {
    to {transform: rotate(360deg);}
  }
}


