.spot-button {
  justify-content: center;
  position: relative;

  &.spot-button--link[disabled] {
    cursor: not-allowed;
  }

  .hidden-content {
    visibility: hidden;
  }

  .button-spinner {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
