.loading-container {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  > div {
    text-align: center;
  }
}
