@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.role-management-wrapper {
  .page-heading {
    width: 370px;
    position: relative;

    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      top: -5px;
    }
  }

  .role-management-card {
    margin-top: 10px;

    .spinner-loader {
      margin-left: 10px;
    }
  }

  .role-management-table {
    margin-left: 0;
    margin-top: 16px;
    font-size: 14px;

    .spot-form__checkbox-group {
      padding: 0;

      .spot-form__checkbox {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .role-management_actions {
    clear: both;
    display: flex;
    margin: 40px 20px 0 20px;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;
    }
  }

}
